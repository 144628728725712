<template>
  <section class="app-container">
    <div class="search">
      <div class="form">
        <div class="label">月份：</div>
        <a-month-picker
          class="item"
          v-model:value="formState.time"
          :disabled-date="disabledDate"
          placeholder="请选择"
          @change="searchClick"
          :allowClear="false"
        />
        <div class="label">数据筛选：</div>
        <a-select
          class="item"
          style="width: 240px"
          v-model:value="formState.reportType"
          placeholder="请选择"
          show-search
          :options="arveReportOps"
          @change="searchClick"
        />
        <div class="label">职级：</div>
        <SelectWithAll
            class="item"
            style="width: 240px"
            v-model:value="formState.grade"
            placeholder="请选择"
            mode="multiple"
            showArrow
            allowClear
            :maxTagCount="3"
            :options="staffGradeOps"
            @change="searchClick"
        >
        </SelectWithAll>
      </div>
      <div>
        <a-space>
          <!--          <a-button type="primary" @click="searchClick">-->
          <!--            筛选-->
          <!--          </a-button>-->
          <a-button type="primary" ghost @click="resetClick"> 重置</a-button>
          <a-button type="primary" @click="exportClick" v-auth="['system:TeamReport:exportMonth']"> 导出</a-button>
        </a-space>
      </div>
    </div>
    <CardTitle :title="reportTitle" size="large" />
    <div ref="tableRef">
      <a-table
        class="org-list"
        ref="certificateListRef"
        :expandedRowKeys="idList"
        :columns="columns"
        :data-source="reportList"
        rowKey="id"
        :scroll="{
          y: tableHeight,
          x: 1180,
          scrollToFirstRowOnChange: true,
        }"
        :pagination="false"
        @expand="expandClick"
      >
        <template #collapse>
          <div class="collapse-cell">
            <div class="collapse-btn" @click="tblCollapse = !tblCollapse">
              <DoubleLeftOutlined v-if="tblCollapse" />
              <DoubleRightOutlined v-else />
            </div>
          </div>
        </template>
      </a-table>
    </div>
  </section>
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import {
  dictionaryOptionsCode,
  downloadFile,
  useTableHeight,
} from "@/utils/common";
import CardTitle from "@/components/CardTitle";
import moment from "moment";
import { computed } from "vue";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "Month",
  components: {
    CardTitle,
    DoubleLeftOutlined,
    DoubleRightOutlined,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const { $EventBus } = useGlobalPropertyHook();

    const formState = reactive({
      reportType: undefined,
      grade: [],
      time: moment(),
    });

    const disabledDate = (current) => {
      return current && current > moment().endOf("day");
    };

    const staffGradeOps = ref([]);
    const arveReportOps = ref([]);

    // 获取筛选框字典
    const getSelectOptions = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "grade,arveReport",
          },
        })
        .then((res) => {
          staffGradeOps.value = dictionaryOptionsCode(res.data.grade);
          arveReportOps.value = dictionaryOptionsCode(res.data.arveReport);
          if (formState.reportType) {
            doSearch();
          } else if (arveReportOps.value && arveReportOps.value.length > 0) {
            formState.reportType = arveReportOps.value[0].value;
            searchClick();
          }
        });
    };

    const certificateListRef = ref();
    const tblCollapse = ref(false);
    const collapseCellFunc = () => {
      return {
        style: {
          "background-color": "#F3F3F3",
        },
      };
    };
    const collapseTitleCellFunc = () => {
      return {
        style: {
          "background-color": "#FBFBFB",
        },
      };
    };
    const columns = computed(() => {
      if (tblCollapse.value) {
        return [
          {
            title: "Team",
            dataIndex: "child",
            key: "child",
            ellipsis: true,
            width: 350,
            fixed: "left",
            resizable: true,
          },
          {
            title: () => {
              return (
                <div>
                  <div>YTD</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "ytdActualArve",
            key: "ytdActualArve",
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: () => {
              return (
                <div>
                  <div>YTD</div>
                  <div>Actual</div>
                  <div>Money</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "ytdActualMoneyArve",
            key: "ytdActualMoneyArve",
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: () => {
              return (
                <div>
                  <div>ARVE</div>
                  <div>Target</div>
                </div>
              );
            },
            dataIndex: "arveTarget",
            key: "arveTarget",
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: () => {
              return (
                <div>
                  <div>Money</div>
                  <div>ARVE</div>
                  <div>Target</div>
                </div>
              );
            },
            dataIndex: "moneyArveTarget",
            key: "moneyArveTarget",
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: () => {
              return (
                <div>
                  <div>MTD</div>
                  <div>Actual</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "mtdActualArve",
            key: "mtdActualArve",
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: () => {
              return (
                <div>
                  <div>MTD</div>
                  <div>Actual</div>
                  <div>Money</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "mtdActualMoneyArve",
            key: "mtdActualMoneyArve",
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: () => {
              return (
                <div>
                  <div>MTD</div>
                  <div>Finance</div>
                  <div>ARVE</div>
                </div>
              );
            },
            dataIndex: "mtdFinanceArve",
            key: "mtdFinanceArve",
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: "WWOC",
            dataIndex: "wwoc",
            key: "wwoc",
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: "BD",
            dataIndex: "bd",
            key: "bd",
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: "IDC",
            dataIndex: "idc",
            key: "idc",
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            dataIndex: "collapse",
            key: "collapse",
            align: "center",
            width: 0,
            slots: { title: "collapse" },
          },
          {
            title: "COE",
            dataIndex: "coe",
            key: "coe",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: "Bench",
            dataIndex: "bench",
            key: "bench",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: () => {
              return (
                <div>
                  <div>Team</div>
                  <div>Management</div>
                </div>
              );
            },
            dataIndex: "teamManagement",
            key: "teamManagement",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 140,
          },
          {
            title: () => {
              return (
                <div>
                  <div>Competence</div>
                  <div>Solution</div>
                </div>
              );
            },
            dataIndex: "competenceSolution",
            key: "competenceSolution",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 130,
          },
          {
            title: () => {
              return (
                <div>
                  <div>EXT</div>
                  <div>Solution</div>
                  <div>Support</div>
                </div>
              );
            },
            dataIndex: "extSolutionSupport",
            key: "extSolutionSupport",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 110,
          },
          {
            title: () => {
              return (
                <div>
                  <div>On-Project</div>
                  <div>Support</div>
                </div>
              );
            },
            dataIndex: "onProjectSupport",
            key: "onProjectSupport",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 120,
          },
          {
            title: () => {
              return (
                <div>
                  <div>Transition</div>
                  <div>Cost</div>
                </div>
              );
            },
            dataIndex: "transitionCost",
            key: "transitionCost",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 110,
          },
          {
            title: () => {
              return (
                <div>
                  <div>Inventorty</div>
                  <div>Investment</div>
                </div>
              );
            },
            dataIndex: "inventortyInvestment",
            key: "inventortyInvestment",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 120,
          },
          {
            title: () => {
              return (
                <div>
                  <div>HC</div>
                  <div>De-Commit</div>
                </div>
              );
            },
            dataIndex: "hcDecommit",
            key: "hcDecommit",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 120,
          },
          {
            title: () => {
              return (
                <div>
                  <div>Booster</div>
                  <div>Program</div>
                </div>
              );
            },
            dataIndex: "boosterProgram",
            key: "boosterProgram",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 110,
          },
          {
            title: "Leave",
            dataIndex: "leaves",
            key: "leaves",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: "Training",
            dataIndex: "training",
            key: "training",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: () => {
              return (
                <div>
                  <div>Other</div>
                  <div>IDC</div>
                </div>
              );
            },
            dataIndex: "otherIdc",
            key: "otherIdc",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100,
          },
          {
            title: () => {
              return (
                <div>
                  <div>Actual</div>
                  <div>FTE</div>
                </div>
              );
            },
            dataIndex: "actualFte",
            key: "actualFte",
            ellipsis: true,
            align: "center",
            width: 100,
          },
        ];
      }
      return [
        {
          title: "Team",
          dataIndex: "child",
          key: "child",
          ellipsis: true,
          width: 300,
          fixed: "left",
          resizable: true,
        },
        {
          title: () => {
            return (
              <div>
                <div>YTD</div>
                <div>Actual</div>
                <div>ARVE</div>
              </div>
            );
          },
          dataIndex: "ytdActualArve",
          key: "ytdActualArve",
          ellipsis: true,
          align: "center",
          width: 100,
        },
        {
          title: () => {
            return (
              <div>
                <div>YTD</div>
                <div>Actual</div>
                <div>Money</div>
                <div>ARVE</div>
              </div>
            );
          },
          dataIndex: "ytdActualMoneyArve",
          key: "ytdActualMoneyArve",
          ellipsis: true,
          align: "center",
          width: 100,
        },
        {
          title: () => {
            return (
              <div>
                <div>ARVE</div>
                <div>Target</div>
              </div>
            );
          },
          dataIndex: "arveTarget",
          key: "arveTarget",
          ellipsis: true,
          align: "center",
          width: 100,
        },
        {
          title: () => {
            return (
              <div>
                <div>Money</div>
                <div>ARVE</div>
                <div>Target</div>
              </div>
            );
          },
          dataIndex: "moneyArveTarget",
          key: "moneyArveTarget",
          ellipsis: true,
          align: "center",
          width: 100,
        },
        {
          title: () => {
            return (
              <div>
                <div>MTD</div>
                <div>Actual</div>
                <div>ARVE</div>
              </div>
            );
          },
          dataIndex: "mtdActualArve",
          key: "mtdActualArve",
          ellipsis: true,
          align: "center",
          width: 100,
        },
        {
          title: () => {
            return (
              <div>
                <div>MTD</div>
                <div>Actual</div>
                <div>Money</div>
                <div>ARVE</div>
              </div>
            );
          },
          dataIndex: "mtdActualMoneyArve",
          key: "mtdActualMoneyArve",
          ellipsis: true,
          align: "center",
          width: 100,
        },
        {
          title: () => {
            return (
              <div>
                <div>MTD</div>
                <div>Finance</div>
                <div>ARVE</div>
              </div>
            );
          },
          dataIndex: "mtdFinanceArve",
          key: "mtdFinanceArve",
          ellipsis: true,
          align: "center",
          width: 100,
        },
        {
          title: "WWOC",
          dataIndex: "wwoc",
          key: "wwoc",
          ellipsis: true,
          align: "center",
          width: 100,
        },
        {
          title: "BD",
          dataIndex: "bd",
          key: "bd",
          ellipsis: true,
          align: "center",
          width: 100,
        },
        {
          title: "IDC",
          dataIndex: "idc",
          key: "idc",
          ellipsis: true,
          align: "center",
          width: 100,
        },
        {
          dataIndex: "collapse",
          key: "collapse",
          align: "center",
          width: 0,
          slots: { title: "collapse" },
        },
        {
          title: () => {
            return (
              <div>
                <div>Actual</div>
                <div>FTE</div>
              </div>
            );
          },
          dataIndex: "actualFte",
          key: "actualFte",
          ellipsis: true,
          align: "center",
          width: 100,
        },
      ];
    });
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const reportList = ref();
    const idList = ref([]);
    let orgArveList = [];
    let searchCondition = {};

    const doSearch = () => {
      idList.value = [];
      reportList.value = [];
      orgArveList = [];
      const params = {
        grade: formState.grade,
        reportType: formState.reportType,
        time: formState.time.format("YYYYMM"),
      };
      $api.getMonthTree(params).then((res) => {
        idList.value = res.data.idList;
        reportList.value = res.data.reportList;
        orgArveList = res.data.orgArveList;
      });
    };

    const expandClick = (expanded, record) => {
      if (expanded) {
        idList.value.push(record.id);
      } else {
        const index = idList.value.indexOf(record.id);
        idList.value.splice(index, 1);
      }
    };

    const searchClick = () => {
      pagination.current = 1;
      doSearch();
    };

    const resetClick = () => {
      Object.assign(formState, {
        reportType:
          arveReportOps.value && arveReportOps.value.length > 0
            ? arveReportOps.value[0].value
            : undefined,
        grade: [],
        time: moment(),
      });
      searchClick();
    };

    const exportClick = () => {
      const params = {
        orgArveList,
        reportType: formState.reportType,
        time: formState.time.format("YYYYMM"),
      };
      $api.teamReportExport(params).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };

    const reportTitle = computed(() => {
      const report = arveReportOps.value.find(
        (item) => item.value === formState.reportType
      );
      return report ? report.label : "";
    });

    $EventBus.on("refreshMonth", () => {
      getSelectOptions();
    });

    const tableHeight = ref(0);
    const tableRef = ref();

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
      // getSelectOptions();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      formState,
      disabledDate,
      staffGradeOps,
      arveReportOps,
      reportTitle,
      certificateListRef,
      tblCollapse,
      columns,
      pagination,
      reportList,
      idList,
      searchClick,
      resetClick,
      tableRef,
      tableHeight,
      doSearch,
      expandClick,
      exportClick,
    };
  },
});
</script>
<style scoped lang="less">
.app-container {
  height: calc(100% - 44px);
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
}

.search {
  margin: 10px 0 24px;
  display: flex;
  justify-content: space-between;

  .form {
    margin-top: 0;
  }
}

.form {
  margin-top: 16px;
  display: flex;
  align-items: center;

  .label {
    //width: 100px;
    margin-right: 16px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #2d3748;
  }

  .item {
    width: 128px;
    margin-right: 40px;
  }
}

.org-list {
  width: 100%;
  max-height: calc(100% - 88px);
  //padding: 10px;
  margin-top: 24px;
  background: #ffffff;
}

.collapse-cell {
  position: relative;
}

.collapse-btn {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(49, 130, 206, 0.2);
  color: #3182ce;
  position: absolute;
  left: -28px;
}
</style>
