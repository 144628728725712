<template>
  <div class="noticeModal">
    <div class="tab">
      <a-tabs v-model:activeKey="activeKey" @change="tabChange">
        <a-tab-pane key="Year" tab="年度报表"></a-tab-pane>
        <a-tab-pane key="Month" tab="月度报表"></a-tab-pane>
      </a-tabs>
    </div>
    <div class="noticeContent">
      <div class="arve-tip">{{ arveTip }}</div>
      <Year v-show="activeKey === 'Year'" />
      <Month v-show="activeKey === 'Month'" />
    </div>
  </div>
</template>
<script>
import { defineComponent, onActivated, ref } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import Month from "@/views/home/ARVE/TeamReport/components/Month";
import Year from "@/views/home/ARVE/TeamReport/components/Year";

export default defineComponent({
  name: "teamReport",
  components: { Year, Month },
  setup() {
    const { $EventBus } = useGlobalPropertyHook();
    const { $api } = useGlobalPropertyHook();

    const activeKey = ref("Year");

    const tabChange = () => {
      getArveTips();
      if (activeKey.value === "Year") {
        $EventBus.emit("refreshYear");
      } else {
        $EventBus.emit("refreshMonth");
      }
    };

    const arveTip = ref("");
    const getArveTips = () => {
      $api.getArveTips().then(res => {
        arveTip.value = res.data;
      });
    }

    onActivated(() => {
      tabChange();
    });

    return {
      activeKey,
      tabChange,
      arveTip
    };
  },
});
</script>
<style lang="less" scoped>
@import "~@/style/theme.less";

.noticeModal {
  margin: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  display: flex;
  flex-direction: column;
}

:deep(.ant-tabs-nav .ant-tabs-tab) {
  font-size: 18px;
  font-weight: 500;
  margin-left: 59px;
}

.search {
  margin-right: 16px;
}

:deep(.ant-tabs) {
  font-family: "PingFang SC";
  font-style: normal;
  line-height: 150%;
  color: #2d3748;

  .ant-tabs-bar {
    margin-bottom: 0;
    border-bottom: none;

    .ant-tabs-nav {
      padding: 15.5px 27px;
      background-color: #fff;
      box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
      border-radius: 0 20px 0 0;
    }

    .ant-tabs-extra-content {
      margin-top: 11px;
    }
  }

  /*   .ant-tabs-tabpane {
    background-color: #fff;
    padding: 24px;
    min-height: 75vh;
  } */

  .ant-tabs-tab {
    font-weight: 400;
    font-size: 18px;
    padding: 0px 16px 6px 16px;
    margin-right: 37px;

    &::before {
      transition: unset;
    }

    &.ant-tabs-tab-active {
      color: #2d3748;
      font-weight: 500;
      font-size: 20px;

      &::before {
        position: absolute;
        top: unset;
        bottom: 1px;
        left: 30%;
        width: 40%;
        background: @primary-color;
      }
    }
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }
}

.noticeContent {
  overflow: auto;
  flex: 1;
  background: white;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    width: 7px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

.paginationBottom {
  background: #fff;
  padding: 8px 30px;
  position: relative;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
}

:deep(.ant-list-item) {
  padding: 11px 30px;
}

:deep(.ant-list-items:first-child) {
  padding-top: 12px;
}

.ml15 {
  margin-left: 15px;
  border-color: #40a9ff;
  color: #40a9ff;
}

.mlb15 {
  margin-left: 15px;
}

:deep(.ant-list-item-action) {
  margin-right: 20px;
}

:deep(.ant-tabs) {
  height: 100%;
}

:deep(.ant-tabs .ant-tabs-top-content.ant-tabs-content-animated) {
  height: 100%;
}

.content {
  font-size: 16px;
}

.arve-tip {
  margin: 24px 20px 0;
  line-height: 20px;
  text-align: right;
  color: #f59723;
}
</style>
